<template>
  <component :is="userData === undefined ? 'div' : 'div'">
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :plan-options="planOptions"
      :assignee-options="list"
      :change-data="changeData"
    />
    <b-row class="mt-1 ml-50">
      <!-- people group 1 -->
      <b-col md="3">
        <h4 class="text-primary font-weight-bold mb-2">1. Candidate</h4>
        <b-card class="card-app-design">
          <h6 class="text-success font-weight-bold mb-2">
            Candidates for this position
          </h6>
          <b-row>
            <draggable
              :list="list1"
              tag="ul"
              group="people"
              class="list-group list-group-flush cursor-move iteme m-0"
              @change="onUnpublishedChange1"
            >
              <b-list-group-item
                class="border-0 m-0 cp_1"
                v-for="(listItem, index) in list1"
                :key="index"
                tag="li"
              >
                <div :id="'ta' + listItem.id" class="d-flex mb-1 ml-1">
                  <b-col md="10" class="d-flex cml_0">
                    <b-avatar
                      size="2rem"
                      :src="getAvatarUrl(listItem.avatarFileUrl)"
                      :text="listItem.fullName.slice(0, 2)"
                    />
                    <div class="ml-1" style="fontsize: 1rem">
                      {{ listItem.fullName }}
                      <!-- <small>{{ listItem.email }}</small> -->
                    </div>
                  </b-col>
                  <b-col>
                    <b-button
                      variant="flat-primary"
                      v-b-modal="modalId(listItem.id)"
                      class="btn-icon"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                    <b-modal
                      :id="'modal' + listItem.id"
                      title="Confirm Delete?"
                    >
                      <div class="alert">
                        Are you sure to remove <b> {{ listItem.fullName }}</b> ?
                      </div>
                      <div slot="modal-footer">
                        <p>
                          <b-button
                            size="md"
                            class="float-left mr-1"
                            variant="secondary"
                            @click="$bvModal.hide('modal' + listItem.id)"
                          >
                            <i class="fas fa-times"></i> No
                          </b-button>
                          <b-button
                            size="md"
                            class="float-right"
                            variant="danger"
                            @click="removeAt(index, listItem.id)"
                          >
                            <i class="fas fa-check"></i> Yes
                          </b-button>
                        </p>
                      </div>
                    </b-modal>
                  </b-col>
                </div>
                <b-tooltip :target="'ta' + listItem.id" triggers="hover">
                  <b>{{ listItem.createdByUserFullName }}</b>
                  <br />
                  {{ dateFormat(listItem.createdOnUtc) }}
                </b-tooltip>
              </b-list-group-item>
              <b-list-group-item
                class="border-0 cp_1"
                v-if="list1.length === 0"
                :key="index"
                tag="li"
              >
                <div class="d-flex justify-content-center">
                  <div>.. empty ..</div>
                </div>
              </b-list-group-item>
            </draggable>
          </b-row>
          <div class="d-flex">
            <b-button
              variant="flat-primary"
              class="btn-icon"
               @click="isAddNewUserSidebarActive = true , type='Position', list=list1"
            
            >
            <!--  @click="report($router.currentRoute.params.id, 'Position')"-->
              Report
            </b-button>
            <b-button
              variant="flat-primary"
              class="btn-icon ml-4"
              :to="{
                name: 'apps-findcondidate',
                params: { id: $router.currentRoute.params.id },
              }"
            >
              + Add Candidate
            </b-button>
          </div>
        </b-card>
        <b-card class="card-app-design">
          <b-row class="mt-2">
            <h6 class="text-danger font-weight-bold ml-1 mb-2">
              Negative Candidates
            </h6>
          </b-row>
          <b-row>
            <draggable
              :list="list5"
              tag="ul"
              group="people"
              class="list-group list-group-flush cursor-move"
              @change="onUnpublishedChange5"
            >
              <b-list-group-item
                class="border-0 cp_1"
                v-for="(listItem, index) in list5"
                :key="index"
                tag="li"
              >
                <div :id="'ta' + listItem.id" class="d-flex mb-1 ml-1">
                  <b-avatar
                    size="2rem"
                    :src="getAvatarUrl(listItem.avatarFileUrl)"
                    :text="listItem.fullName.slice(0, 2)"
                  />
                  <div class="ml-1">
                    {{ listItem.fullName }}

                    <!-- <small>{{ listItem.email }}</small> -->
                  </div>
                </div>
                <b-tooltip :target="'ta' + listItem.id" triggers="hover">
                  <b>{{ listItem.updatedByUserFullName }}</b>
                  <br />
                  {{ dateFormat(listItem.updatedOnUtc) }}
                </b-tooltip>
              </b-list-group-item>
              <b-list-group-item
                class="border-0 cp_1"
                v-if="list5.length === 0"
                :key="index"
                tag="li"
              >
                <div class="d-flex justify-content-center">
                  <div class="ml-25">.. empty ..</div>
                </div>
              </b-list-group-item>
            </draggable>
          </b-row>
          <div class="d-flex">
            <b-button
              variant="flat-primary"
              class="btn-icon mr-3"
               @click="isAddNewUserSidebarActive = true , type='Negative', list=list5"
            
            >
            <!--  @click="report($router.currentRoute.params.id, 'Negative')"-->
              Report
            </b-button>
            <b-button variant="flat-primary" class="btn-icon ml-3">
              Send Email
            </b-button>
          </div>
        </b-card>
      </b-col>

      <!-- people group 2 -->
      <b-col md="3" class="mt-sm-2 mt-md-0">
        <h4 class="text-primary font-weight-bold mb-2">
          2. Those in the HR Process
        </h4>
        <b-card class="card-app-design">
          <h6 class="text-success font-weight-bold mb-2">First Interview</h6>
          <b-row>
            <draggable
              :list="list2"
              tag="ul"
              group="people"
              class="list-group list-group-flush cursor-move"
              @change="onUnpublishedChange2"
            >
              <b-list-group-item
                class="border-0 cp_1"
                v-for="(listItem, index) in list2"
                :key="index"
                tag="li"
              >
                <div :id="'ta' + listItem.id" class="d-flex mb-1 ml-1">
                  <b-avatar
                    size="2rem"
                    :src="getAvatarUrl(listItem.avatarFileUrl)"
                    :text="listItem.fullName.slice(0, 2)"
                  />
                  <div class="ml-1">
                    {{ listItem.fullName }}

                    <!-- <small>{{ listItem.email }}</small> -->
                  </div>
                </div>
                <b-tooltip :target="'ta' + listItem.id" triggers="hover">
                  <b>{{ listItem.updatedByUserFullName }}</b>
                  <br />
                  {{ dateFormat(listItem.updatedOnUtc) }}
                </b-tooltip>
              </b-list-group-item>
              <b-list-group-item
                class="border-0 cp_1"
                v-if="list2.length === 0"
                :key="index"
                tag="li"
              >
                <div class="d-flex justify-content-center">
                  <div class="ml-25">.. empty ..</div>
                </div>
              </b-list-group-item>
            </draggable>
          </b-row>
          <div class="d-flex">
            <b-button
              variant="flat-primary"
              class="btn-icon mr-3"

                @click="isAddNewUserSidebarActive = true , type='First', list=list2"
            >
            <!-- @click="report($router.currentRoute.params.id, 'First')" -->
              Report
            </b-button>
          </div>
        </b-card>
        <b-card class="card-app-design">
          <b-row class="mt-2">
            <h6 class="text-warning font-weight-bold ml-1 mb-2">
              Report to Customer
            </h6>
          </b-row>
          <b-row>
            <draggable
              :list="list6"
              tag="ul"
              group="people"
              class="list-group list-group-flush cursor-move"
              @change="onUnpublishedChange6"
            >
              <b-list-group-item
                class="border-0 cp_1"
                v-for="(listItem, index) in list6"
                :key="index"
                tag="li"
              >
                <div :id="'ta' + listItem.id" class="d-flex mb-1 ml-1">
                  <b-avatar
                    size="2rem"
                    :src="getAvatarUrl(listItem.avatarFileUrl)"
                    :text="listItem.fullName.slice(0, 2)"
                  />
                  <div class="ml-1">
                    {{ listItem.fullName }}

                    <!-- <small>{{ listItem.email }}</small> -->
                  </div>
                </div>
                <b-tooltip :target="'ta' + listItem.id" triggers="hover">
                  <b>{{ listItem.updatedByUserFullName }}</b>
                  <br />
                  {{ dateFormat(listItem.updatedOnUtc) }}
                </b-tooltip>
              </b-list-group-item>
              <b-list-group-item
                class="border-0 cp_1"
                v-if="list6.length === 0"
                :key="index"
                tag="li"
              >
                <div class="d-flex justify-content-center">
                  <div class="ml-25">.. empty ..</div>
                </div>
              </b-list-group-item>
            </draggable>
          </b-row>
          <div class="d-flex">
            <b-button
              variant="flat-primary"
              class="btn-icon mr-3"
                @click="isAddNewUserSidebarActive = true , type='Report', list=list6"
             
            >
            <!--@click="report($router.currentRoute.params.id, 'Report')" --> 
              Report
            </b-button>
          </div>
        </b-card>
      </b-col>

      <b-col md="3" class="mt-sm-2 mt-md-0">
        <h4 class="text-primary font-weight-bold mb-2">
          3. Meeting With Customer
        </h4>
        <b-card class="card-app-design">
          <h6 class="text-success font-weight-bold mb-2">
            Interviewed Candidates
          </h6>

          <draggable
            :list="list3"
            tag="ul"
            group="people"
            class="list-group list-group-flush cursor-move"
            @change="onUnpublishedChange3"
          >
            <b-list-group-item
              class="border-0 cp_1"
              v-for="(listItem, index) in list3"
              :key="index"
              tag="li"
            >
              <div :id="'ta' + listItem.id" class="d-flex mb-1 ">
                <b-avatar
                  size="2rem"
                  :src="getAvatarUrl(listItem.avatarFileUrl)"
                  :text="listItem.fullName.slice(0, 2)"
                />
                <div class="ml-1">
                  {{ listItem.fullName }}

                  <!-- <small>{{ listItem.email }}</small> -->
                </div>
              </div>
              <b-tooltip :target="'ta' + listItem.id" triggers="hover">
                <b>{{ listItem.updatedByUserFullName }}</b>
                <br />
                {{ dateFormat(listItem.updatedOnUtc) }}
              </b-tooltip>
            </b-list-group-item>
            <b-list-group-item
              class="border-0 cp_1"
              v-if="list3.length === 0"
              :key="index"
              tag="li"
            >
              <div class="d-flex">
                <div class="ml-25">.. empty ..</div>
              </div>
            </b-list-group-item>
          </draggable>
          <div class="d-flex">
            <b-button
              variant="flat-primary"
              class="btn-icon mr-3"
               @click="isAddNewUserSidebarActive = true , type='Interviewed', list=list3"
              
            >
            <!--@click="report($router.currentRoute.params.id, 'Interviewed')"-->
              Report
            </b-button>
            <b-button variant="flat-primary" class="btn-icon ml-3">
              Send Email
            </b-button>
          </div>
        </b-card>
      </b-col>

      <b-col md="3" class="mt-sm-2 mt-md-0">
        <h4 class="text-primary font-weight-bold mb-2">
          4. Candidates Accepted
        </h4>
        <b-card class="card-app-design">
          <h6 class="text-success font-weight-bold mb-2">
            Candidates Accepted
          </h6>
          <!-- draggable -->
          <draggable
            :list="list4"
            tag="ul"
            group="people"
            class="list-group list-group-flush cursor-move"
            @change="onUnpublishedChange4"
          >
            <b-list-group-item
              class="border-0 cp_1"
              v-for="(listItem, index) in list4"
              :key="index"
              tag="li"
            >
              <div :id="'ta' + listItem.id" class="d-flex mb-1 ">
                <b-avatar
                  size="2rem"
                  :src="getAvatarUrl(listItem.avatarFileUrl)"
                  :text="listItem.fullName.slice(0, 2)"
                />
                <div class="ml-1">
                  {{ listItem.fullName }}

                  <!-- <small>{{ listItem.email }}</small> -->
                </div>
              </div>
              <b-tooltip :target="'ta' + listItem.id" triggers="hover">
                <b>{{ listItem.updatedByUserFullName }}</b>
                <br />
                {{ dateFormat(listItem.updatedOnUtc) }}
              </b-tooltip>
            </b-list-group-item>
            <b-list-group-item
              class="border-0 cp_1"
              v-if="list4.length === 0"
              :key="index"
              tag="li"
            >
              <div class="d-flex">
                <div class="ml-25">.. empty ..</div>
              </div>
            </b-list-group-item>
          </draggable>
          <div class="d-flex">
            <b-button variant="flat-primary" class="btn-icon ml-3">
              Send Email
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="modal" ref="Close-modal" title="Confirm Close Positions?">
      <div class="alert">Are you sure to Close Positions ?</div>
      <div slot="modal-footer">
        <p>
          <b-button
            size="md"
            class="float-left mr-1"
            variant="secondary"
            @click="$bvModal.hide('modal')"
          >
            <i class="fas fa-times"></i> No
          </b-button>
          <b-button
            size="md"
            class="float-right"
            variant="danger"
            @click="closePositions()"
          >
            <i class="fas fa-check"></i> Yes
          </b-button>
        </p>
      </div>
    </b-modal>
  </component>
</template>

<script>
import {
  BListGroupItem,
  BAvatar,
  BRow,
  BCol,
  BButton,
  BCard,
  BCardText,
  BTooltip,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import userStoreModule from "../companyStoreModule";
import moment from "moment";
import UserListAddNew from "./Report.vue";

export default {
  components: {
    BAvatar,
    BListGroupItem,
    BRow,
    BCol,
    Prism,
    draggable,
    BButton,
    BCard,
    BCardText,
    BTooltip,
    UserListAddNew
  },
  data() {
    return {
      list: [],
      list1: [],
      list2: [],
      list3: [],
      list4: [],
      list5: [],
      list6: [],
      type:"",
    };
  },
  watch: {
    candidateData(newcandidateData, oldcandidateData) {
      this.list1 = newcandidateData.filter((x) => x.status === "Position");
      this.list2 = newcandidateData.filter((x) => x.status === "First");
      this.list3 = newcandidateData.filter((x) => x.status === "Interviewed");
      this.list4 = newcandidateData.filter((x) => x.status === "Accepted");
      this.list5 = newcandidateData.filter((x) => x.status === "Negative");
      this.list6 = newcandidateData.filter((x) => x.status === "Report");
    },
    list4(newlist4, olist4) {
      if(this.list4.length > 0)
      this.$refs["Close-modal"].show();
    },
  },
  methods: {
 changeData(reportList){
   const id= router.currentRoute.params.id;
   const status = this.type;
     store
        .dispatch("company/fetchPdf", { id, status , reportList })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const objectUrl = window.URL.createObjectURL(blob);
          window.open(objectUrl, "_blank");
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(
            error.response.data.errors
          )) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: value,
              },
            });
          }
        });

 },
    showModal() {
      this.$refs["Close-modal"].show();
    },
    dateFormat(date) {
      return moment(String(date))
        ? moment(String(date)).format("DD/MM/YYYY HH:mm")
        : date;
    },
    getAvatarUrl(Url) {
      return process.env.VUE_APP_BASE_URL + Url;
    },
    report(id, type) {
      store
        .dispatch("company/fetchPdf", { id, type })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const objectUrl = window.URL.createObjectURL(blob);
          window.open(objectUrl, "_blank");
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(
            error.response.data.errors
          )) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: value,
              },
            });
          }
        });
    },
    closePositions() {
     store
        .dispatch("company/closeJob", router.currentRoute.params.id)
          .then((response) => {
           router.push(`/apps/companies/edit/${this.conpamyId}`)
        })
     
      this.$refs["Close-modal"].hide();
    },
    removeAt(idx, id) {
      this.$bvModal.hide("modal" + id);
      this.list1.splice(idx, 1);
      this.deleteData(id);
    },
    modalId(id) {
      return "modal" + id;
    },
    deleteData(id) {
      store
        .dispatch("company/deleteInterview", {
          id: id,
        })
        .then((response) => {})
        .catch((error) => {
          for (const [key, value] of Object.entries(
            error.response.data.errors
          )) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: value,
              },
            });
          }
        });
    },
    onUnpublishedChange1({ added }) {
      if (added) {
        const data = {
          ...added.element,
          status: "Position",
        };
        store
          .dispatch("company/editInterview", data)
          .then((response) => {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "updated successfully",
                icon: "AlertTriangleIcon",
                variant: "add to Position ",
              },
            });
          })
          .catch((error) => {
            for (const [key, value] of Object.entries(
              error.response.data.errors
            )) {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: "error",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: value,
                },
              });
            }
          });
      }
    },
    onUnpublishedChange2({ added }) {
      if (added) {
        const data = {
          ...added.element,
          status: "First",
        };
        store
          .dispatch("company/editInterview", data)
          .then((response) => {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "updated successfully",
                icon: "AlertTriangleIcon",
                variant: "add to First Interviewe ",
              },
            });
          })
          .catch((error) => {
            for (const [key, value] of Object.entries(
              error.response.data.errors
            )) {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: "error",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: value,
                },
              });
            }
          });
      }
    },
    onUnpublishedChange3({ added }) {
      if (added) {
        const data = {
          ...added.element,
          status: "Interviewed",
        };
        store
          .dispatch("company/editInterview", data)
          .then((response) => {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "updated successfully",
                icon: "AlertTriangleIcon",
                variant: "add to Interviewed ",
              },
            });
          })
          .catch((error) => {
            for (const [key, value] of Object.entries(
              error.response.data.errors
            )) {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: "error",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: value,
                },
              });
            }
          });
      }
    },
    onUnpublishedChange4({ added }) {
      if (added) {
        const data = {
          ...added.element,
          status: "Accepted",
        };
        store
          .dispatch("company/editInterview", data)
          .then((response) => {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "updated successfully",
                icon: "AlertTriangleIcon",
                variant: "add to Accepted ",
              },
            });
          })
          .catch((error) => {
            for (const [key, value] of Object.entries(
              error.response.data.errors
            )) {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: "error",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: value,
                },
              });
            }
          });
      }
    },
    onUnpublishedChange5({ added }) {
      if (added) {
        const data = {
          ...added.element,
          status: "Negative",
        };
        store
          .dispatch("company/editInterview", data)
          .then((response) => {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "updated successfully",
                icon: "AlertTriangleIcon",
                variant: "add to Negative ",
              },
            });
          })
          .catch((error) => {
            for (const [key, value] of Object.entries(
              error.response.data.errors
            )) {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: "error",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: value,
                },
              });
            }
          });
      }
    },
    onUnpublishedChange6({ added }) {
      if (added) {
        const data = {
          ...added.element,
          status: "Report",
        };
        store
          .dispatch("company/editInterview", data)
          .then((response) => {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "updated successfully",
                icon: "AlertTriangleIcon",
                variant: "add to Report ",
              },
            });
          })
          .catch((error) => {
            for (const [key, value] of Object.entries(
              error.response.data.errors
            )) {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: "error",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: value,
                },
              });
            }
          });
      }
    },
  },
  setup() {
    const userData = ref(null);
    const candidateData = ref([]);
    const isAddNewUserSidebarActive = ref(false);
    const USER_APP_STORE_MODULE_NAME = "company";
    const conpamyId = ref(null)
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("company/fetchJobId", { id: router.currentRoute.params.id })
      .then((response) => {
        userData.value = response.data.data;
        conpamyId.value = response.data.data.companyId
        store.commit("user/UPDATE_COMPAMYID", response.data.data.companyId);
        store.commit("user/UPDATE_COMPAMY", response.data.data.companyName);
        store.commit("user/UPDATE_JOB", response.data.data.title);
      })
      .catch((error) => {});
    store
      .dispatch("company/fetchInterview", {
        pageSize: 300,
        currentPage: 1,
        jobId: router.currentRoute.params.id,
      })
      .then((response) => {
        candidateData.value = response.data.data.items;
      })
      .catch((error) => {});

    return {
      userData,
      candidateData,
      isAddNewUserSidebarActive,
      conpamyId
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.cbox {
  border: 3px solid transparent;
  border-left: 1px solid rgb(210, 209, 220);
}
.cml_0 {
  margin-left: -10px;
  margin-top: 0;
  margin-bottom: 0;
}
.cp_1 {
  padding: 1px !important;
}
.iteme {
  width: 300px;
}
</style>